import { FormControl, Grid, InputLabel, Stack } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useContext, useEffect, useState } from "react";
import global_context, { api_url, GlobalContext, leagues } from "../../static_vars";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import StyledAutocomplete from "./Styles/StyledAutocomplete";
import "./Styles/listbox.css";
import { Champion, useReferentialContext } from "../../Context";
import { SingleSelectorGrouped } from "../Abstract/Single";
import { Team } from "../../farsight-api";

export default function TeamsSelect(props: { leagues: string[]; value: any; onChange: any }) {
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        fetch(api_url + "BayesTeams/?leagues=" + props.leagues.toString())
            .then((response) => response.json())
            .then((data) => setTeams(data));
    }, [props.leagues]);
    return (
        <FormControl sx={{ width: "50%" }}>
            <Autocomplete limitTags={1} multiple id="combo-box-demo" options={teams} value={props.value} onChange={props.onChange} renderInput={(params) => <TextField {...params} label="Teams" />} ListboxProps={{ className: "myCustomList" }} />
        </FormControl>
    );
}

export function TeamSelect(props: { value: Team | null; updateValue: any; updateContext: boolean | undefined, label?:string }) {
    const { teamsReferential, selectedTeam, setSelectedTeam } = useReferentialContext();

    const [localTeam, setLocalTeam] = useState(props.value);

    const updateSelection = (newSelection: Team | null) => {
        if (props.updateContext) {
            setSelectedTeam(newSelection);
        } else {
            setLocalTeam(newSelection);
        }
        props.updateValue(newSelection);
    };

    return (
        <Box sx={{ width: "250px", maxWidth:'100%' }}>
            <SingleSelectorGrouped
                getGroup={(option: Team) => `${option.current_league}`}
                getOptionLabel={(option: Team) => option.team_name}
                getOptionShort={(option: Team) => option.team_name}
                options={teamsReferential}
                selected={props.updateContext ? selectedTeam : localTeam}
                updateSelection={updateSelection}
                optionDisabled={(option) => false}
                label={props.label ? props.label : "Team"}
                optionDecoration={option => <Box component={'img'} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/TeamsLogo/${option.team_name}.png`} sx={{mr:'8px'}}/>}
                inputDecoration={(option) => <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/TeamsLogo/${option.team_name}.png`} sx={{ mr: "8px" }} />}
            />
        </Box>
    );
}
