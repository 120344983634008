import {Fade, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {targets, useReferentialContext} from "../../Context";


export default function ChampionIcon(props: {champion: string, role:string, sx? :any, size?: number}){
    const ctx = useReferentialContext();
    let details_page = ctx.currentTarget === targets.competitive ? 1 : ctx.currentTarget === targets.soloq ? 18 : 1
    return <Tooltip title={`Check ${ctx.ddragon_champions[props.champion].name} stats for ${ctx.currentTarget}`} followCursor={true}>
            <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${props.size ? props.size : 40},w_${props.size ? props.size : 40}/v1/championAssets/${props.champion}.png`}
            onClick={() => {
                ctx.setSelectedRole(props.role);
                ctx.setSelectedChampion(ctx.championsReferential.filter(c => c.id===props.champion)[0]);
                ctx.setCurrentTab(details_page);
            }} sx={{cursor: 'pointer', ...props.sx}}/>
            </Tooltip>
}