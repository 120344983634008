import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
    // components: {
    //     MuiPaper: {
    //         styleOverrides: {
    //             root: {
    //                 // background: 'rgba(20, 20, 30, 1)', // Remove unwanted MUI styles
    //                 backgroundColor: 'rgba(20, 20, 30, 0.95)', // Apply custom background
    //             },
    //         },
    //     },
    // },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: {
            main: '#90caf9',
          },
          secondary: {
            main: '#dc004e',
          },
          background: {
            default: '#ffffff',
            paper: '#f4f6f8',
          },
          text: {
            primary: '#000000',
            secondary: '#555555',
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: '#ffffff',
          },
          secondary: {
            main: '#f48fb1',
          },
          background: {
            default: 'rgba(20, 20, 30, 0.95)',
            paper: "rgba(20, 20, 30, 0.95)",
          },
          text: {
            primary: '#ffffff',
            secondary: '#bbbbbb',
          },
        }),
  },
  typography: {
    fontFamily: "'Poppins', sans-serif", // Replace with your desired font
  },
});

export const createAppTheme = (mode: PaletteMode) => createTheme(getDesignTokens(mode));
