import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Stack
} from "@mui/material";
import React, {SetStateAction, useState} from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {soloq_servers, targets, useReferentialContext} from "../../../../Context";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import GamesHistory from "../../../../components/datagrids/shared/GamesHistory";
import {Player} from "../../../../farsight-api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function SoloQPlayerSummary(props: {player?: Player}) {
    const context = useReferentialContext();
    const api = context.farsightApi.getSummariesApi(targets.soloq);

    const [from, setFrom] = useState(dayjs().subtract(14, "day"));
    const [until, setUntil] = useState(dayjs().add(1,"day"));
    const [player, setPlayer] = useState(props.player ? props.player : context.selectedPlayer);
    const [updating, setUpdating] = useState(false);

    const [paramObject, setParamObject] = useState({} as any);

    const [metrics, setMetrics] = useState(context.metricsReferential.filter(m => ["performance", "relative_performance", "games", "winrate", 'gd15', 'csd15'].includes(m.code)));

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <Stack direction={'row'} sx={{width:'100%', alignItems:'space-between'}} spacing={1}>
                <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} player={player} setPlayer={setPlayer}
                        date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil}/>

                {updating ? <CircularProgress/> : <Button variant={'outlined'} onClick={() => {
                    if (player) {
                        setUpdating(true);
                        context.farsightApi.player.update_accounts({id: player.player}).then(r => {
                            setParamObject({...paramObject, watermark:'refresh'})
                            setUpdating(false);
                        });
                    }
                }}>Update</Button>}
                {player ? <RiotAccountFormDialog player={player}
                                        onSubmit={() => setParamObject({...paramObject, watermark: 'refresh'})}/> : <CircularProgress/>}
            </Stack>
            {player && paramObject.competitive_player ? <Stack direction="row" sx={{height: "100%",justifyContent: "center",width: "100%"}} spacing={1}>
                <Stack sx={{ height: "100%", justifyContent: "center", width: "35%"}} spacing={1}>
                    <Box sx={{ height: "50%", justifyContent: "center", width: "100%"}}>
                        <TableDisplayGrid
                            display_all={false}
                            request={() => context.farsightApi.soloqAccount.list({
                                player:player.player,
                                ordering: ['-elo_as_total_lps']
                            })}
                            rowStyle={rowStyle.none}
                            disp_columns={['account_name','elo','server','season_wins','season_losses','source','tracked']}
                            sortBy={"-elo_as_total_lps"}
                            paramObject={paramObject}
                        />
                    </Box>
                    <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate({
                            ...paramObject,
                            groupby: 'champion',
                            metrics: ["performance", "relative_performance", "games", "winrate", 'gd15', 'csd15'].toString(),
                            ordering: "performance",
                            format: "json",
                        })}
                        rowStyle={rowStyle.none}
                        disp_columns={['champion']}
                        sortBy={"games"}
                        paramObject={paramObject}
                        metrics={metrics}
                    />
                </Stack>
                <Box sx={{height: "100%",justifyContent: "center",width: "65%", }}>
                    <GamesHistory paramObject={paramObject} target={targets.soloq} hide_columns={['competitive_player','gd8','csd8']}/>
                </Box>
            </Stack> : <></>}
        </WindowContainer>
    );
}

const ServerSelect = (props: { server: soloq_servers, setServer: any }) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Server</InputLabel>
      <Select
          multiple={false}
        value={props.server}
        onChange={(e) => props.setServer(e.target.value as soloq_servers)}
        label="Server"
      >
        {Object.entries(soloq_servers).map(([key, value]) => (
          <MenuItem key={key} value={key}>
              {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const RiotAccountFormDialog = (props: {player: Player, onSubmit: any}) => {
  const ctx = useReferentialContext();
  const [open, setOpen] = useState(false);
  const [riotId, setRiotId] = useState("");
  const [tagline, setTagline] = useState("");
  const [server, setServer] = useState(soloq_servers.EUW1);
  const [source, setSource] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    // Handle form submission logic here
    const formData = { riotId, tagline, server, source };
    console.log("Form Data:", formData);
    ctx.farsightApi.player.add_account({
        id: props.player.player, add_account: {server: server, account_name: `${riotId}-${tagline}`, source: source}
    })
      props.onSubmit()
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Account
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Riot Account Form</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Riot ID"
            value={riotId}
            onChange={(e) => setRiotId(e.target.value)}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Tagline"
            value={tagline}
            onChange={(e) => setTagline(e.target.value)}
          />

          <ServerSelect server={server} setServer={setServer} />

          <FormControl component="fieldset" margin="normal">
            <RadioGroup
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              <FormControlLabel
                value="Lolpros"
                control={<Radio />}
                label="Lolpros"
              />
              <FormControlLabel
                value="Deeplol"
                control={<Radio />}
                label="Deeplol"
              />
              <FormControlLabel
                value="None"
                control={<Radio />}
                label="None"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
