import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { FormControl, InputLabel, Stack, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {useReferentialContext} from "../../Context";

export default function RoleSelect(props: { value: any; onChange: any; label?: string, updateContext? : boolean }) {
    const ctx = useReferentialContext();
    const labels: any = {
        TOP_LANE: "Top",
        JUNGLE: "Jungle",
        MID_LANE: "Mid",
        BOT_LANE: "Bot",
        UTILITY: "Support",
    };
    console.log(props.value)
    return (
        <FormControl sx={{ width: "150px", height: "57px", zIndex: 0 }}>
            <InputLabel>{props.label ? props.label : "Role"}</InputLabel>
            <Select
                value={props.value}
                label={props.label ? props.label : "Role"}
                onChange={(event) => {
                    if(props.updateContext){
                        ctx.setSelectedRole(event.target.value);
                    }
                    props.onChange(event.target.value)
                }}
                renderValue={(value: string) => (
                    <Stack direction={"row"} sx={{ justifyContent: "flex-start", width: "100%", height:'100%' }} spacing={2}>
                        <img src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${23},w_${23}/v1/leagueAssets/${value}.png`} style={{ marginRight: 10 }} />
                        {labels[value]}
                    </Stack>
                )}
            >
                {Object.entries(labels).map(([code, label]) => (
                    <MenuItem value={code}>
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                alignItems: "center",
                            }}
                            spacing={2}
                        >
                            <img src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${23},w_${23}/v1/leagueAssets/${code}.png`} style={{marginRight: 10}}/>
                            {labels[code]}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function MultipleRoleSelect(props: { values: string[]; updateValue: (newValue: string[]) => void }) {
    const labels: any = {
        TOP_LANE: "Top",
        JUNGLE: "Jungle",
        MID_LANE: "Mid",
        BOT_LANE: "Bot",
        UTILITY: "Support",
    };
    return (
        <FormControl sx={{ width: "170px", height: "55px", zIndex: 0 }}>
            <InputLabel>Role</InputLabel>
            <Select
                multiple
                value={props.values as any}
                label="Role"
                onChange={(event) => props.updateValue(event.target.value as any)}
                renderValue={(value: string) => (
                    <Stack direction={"row"} sx={{ justifyContent: "left", width: "100%" }} spacing={0}>
                        {props.values.map((role: string) => {
                            return <img src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${role}.png`} width={25} height={25} />;
                        })}
                    </Stack>
                )}
            >
                {Object.entries(labels).map(([code, label]) => (
                    <MenuItem value={code}>
                        <Stack
                            direction={"row"}
                            sx={{
                                justifyContent: "flex-start",
                                width: "100%",
                                alignItems: "center",
                            }}
                            spacing={2}
                        >
                            <img src={`https://res.cloudinary.com/xenesis/image/upload/v1/leagueAssets/${code}.png`} width={25} height={25} style={{ marginRight: 10 }} />
                            {labels[code]}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function RoleSelectWithNone(props: { value: any; onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined }) {
    return (
        <FormControl sx={{ m: 1, width: 100, mt: 3 }}>
            <InputLabel id="role">Role</InputLabel>
            <Select labelId="role" id="role" value={props.value} label="Champion" onChange={props.onChange}>
                <MenuItem value={"TOP_LANE"}>Top</MenuItem>
                <MenuItem value={"JUNGLE"}>Jungle</MenuItem>
                <MenuItem value={"MID_LANE"}>Mid</MenuItem>
                <MenuItem value={"BOT_LANE"}>Adc</MenuItem>
                <MenuItem value={"UTILITY"}>Support</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
            </Select>
        </FormControl>
    );
}
