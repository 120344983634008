import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useMemo, useRef } from "react";

// 🔹 Access MUI theme primary color
export const getNeonColor = (theme: any) => theme.palette.primary.main;

// Function to generate a stable random position per dialog instance
const generateBlurPosition = (seed: string) => {
  const hash = seed.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const randomFactor = Math.random() * 50;

  return {
    top: `${(hash % 50) + randomFactor}px`,
    left: `${(hash % 50) + randomFactor}px`,
  };
};

// 🔹 Styled Neon Dialog
export const NeonDialog = styled(({ id, ...props }: DialogProps & { id?: string }) => {
  const ref = useRef<HTMLDivElement>(null);
  const stableId = useMemo(() => id || Math.random().toString(36).substr(2, 9), [id]);
  const blurPosition = useMemo(() => generateBlurPosition(stableId + Math.random().toString()), [stableId]);

  return (
    <Dialog ref={ref} id={stableId} {...props}>
      <div className="neon-glow" style={{ top: blurPosition.top, left: blurPosition.left }} />
      {props.children}
    </Dialog>
  );
})(({ theme }) => ({
  backdropFilter: "blur(10px)",
  background: "transparent",
  borderRadius: "12px",
  boxShadow: `0px 0px 40px rgba(25, 25, 35, 0.95)`,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "stretch",
  margin: "auto",
  padding: 0,
  "& .neon-glow": {
    position: "absolute",
    width: "300px",
    height: "300px",
    background: getNeonColor(theme),
    opacity: 0.1,
    filter: "blur(60px)",
    borderRadius: "50%",
    pointerEvents: "none",
    zIndex: 0,
  },
  "& > *:not(.neon-glow)": {
    position: "relative",
    zIndex: 1,
  },

}));

// 🔹 Styled Neon Dialog Title
export const NeonDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: getNeonColor(theme),
  fontWeight: "bold",
  background: theme.palette.background.paper,
  padding: "16px",
  flexShrink: 0,
}));

// 🔹 Styled Neon Dialog Content
export const NeonDialogContent = styled(DialogContent)(({ theme }) =>({
  flexGrow: 1,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "stretch",
  background: theme.palette.background.paper,
  width: "100%",
  height: "100%",
}));

// 🔹 Styled Neon Dialog Actions
export const NeonDialogActions = styled(DialogActions)(({ theme }) =>({
  justifyContent: "flex-end",
  // background: "rgba(20, 20, 30, 0.9)",
  padding: "15px",
  flexShrink: 0,
  background: theme.palette.background.paper,
  "&::after": {
    content: "''",
    position: "absolute",
    bottom: "0px",
    left: "10%",
    width: "80%",
    height: "2px",
    background: getNeonColor(theme),
    boxShadow: `0px 0px 15px ${getNeonColor(theme)}`,
    opacity: 1,
    pointerEvents: "none",
  },
}));
