import {Team} from "../../farsight-api";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {Tooltip} from "@mui/material"


export default function TeamIcon(props: {team_name: string, sx? :any}){
    const [src, setSrc] = useState(
        `https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${props.sx?.height ? props.sx.height.toString().replace('px','') : 40},w_${props.sx?.width ? props.sx.width.toString().replace('px','') : 40}/TeamsLogo/${props.team_name}.png`
    );

    useEffect(() => {
        setSrc(`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${props.sx?.height ? props.sx.height.toString().replace('px','') : 40},w_${props.sx?.width ? props.sx.width.toString().replace('px','') : 40}/TeamsLogo/${props.team_name}.png`);
    }, [props.team_name]);
    const handleImageError = () => {
        setSrc(`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/teamsLogo/Boa.png`); // Replace with the path to your placeholder image
    };

    return <Tooltip title={props.team_name} followCursor={true}>
        <Box
                component={"img"}
                src={src}
                onError={() => handleImageError()}
                sx={props.sx}
            />
        </Tooltip>
}