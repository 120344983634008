import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import global_context, { api_url, GlobalContext } from "../../static_vars";
import { SelectChangeEvent } from "@mui/material/Select";
import {Checkbox, Fade, FormControl, Grow, Stack, Typography} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";
import {Player} from "../../farsight-api";
import {useReferentialContext} from "../../Context";

export default function PlayerSelect(props: { value: Player|null; onChange: any, referential: Player[] }) {
    const [inputValue, setInputValue] = useState(props.value?.player);
    const [possibilities, setPossibilities] = useState([] as Player[]);
    const [clicked, setClicked] = useState(false);
    const [reload, setReload] = useState(true);


    const filterOptions = (inputValue: string) => {
        return props.referential.filter((player: Player) => player.player.toLowerCase().startsWith(inputValue.toLowerCase()));
    };

    const countPossibilities = (inputValue: any) => {
        const filteredOptions = filterOptions(inputValue);
        return filteredOptions.length;
    };

    const handleInputChange = (event: any, newInputValue: any) => {
        if (props.value && newInputValue !== props.value.player) {
            setInputValue(newInputValue);
            const numPossibilities = countPossibilities(newInputValue);
            setClicked(true);
            setPossibilities(numPossibilities < 1000 ? filterOptions(newInputValue) : []);
        }
    };
    const handleAutocompleteClick = () => {
        setClicked(true);
        if(inputValue){
            const numPossibilities = countPossibilities(inputValue);
            if (numPossibilities < 1000) {
                setPossibilities(filterOptions(inputValue));
            }
        }
    };

    const handleBlur = () => {
        setClicked(false);
        setPossibilities([]);
    };

    const handleValueChange = (event: any, player: Player|null) => {
        if (player) {
            setReload(false);
            setPossibilities([]);
            props.onChange(player ? player : null);
            setClicked(false);
        }
    };

    return (
        <FormControl sx={{ width: "300px", zIndex: 0 }}>
            <Autocomplete
                options={possibilities}
                value={props.value}
                onChange={handleValueChange}
                renderInput={(params) => {
                    setReload(true);
                    return (
                        props.value ?
                    <TextField
                        {...params}
                        label="Player"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                    <Stack direction={"row"}>
                                        {props.value.role && <Box component={"img"}
                                                                   src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/leagueAssets/${props.value.role}.png`} style={{marginRight: 10}}/>}
                                        {<Box>{props.value.current_team ? "" :  (props.value.last_team ? "Prev:" : "")}</Box>}
                                        {props.value.current_team || props.value.last_team ? (
                                            <Box
                                                component={"img"}
                                                src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/teamsLogo/${props.value.current_team ? props.value.current_team : props.value.last_team}.png`}
                                                sx={{
                                                    filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",
                                                    mr: "10px",
                                                }}
                                            />
                                        ) : <></>}
                                        {params.InputProps.startAdornment}
                                    </Stack>
                            ),
                        }}
                    /> : <TextField {...params} label={"Player"} variant="outlined" />

                    );
                }}
                onInputChange={handleInputChange}
                open={clicked && possibilities.length > 0}
                onClick={handleAutocompleteClick}
                onBlur={handleBlur}
                getOptionLabel={(option: any) => {
                    return option.player;
                }}
                renderOption={(props, option, { selected }) => {
                    const role_img = option.role ? <img src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/leagueAssets/${option.role}.png`} style={{ marginRight: 10 }} /> : <></>;
                    const team_img = option.current_team ? (
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${25},w_${25}/v1/teamsLogo/${option.current_team}.png`}
                            sx={{ filter: "filter: invert(100%) hue-rotate(180deg) brightness(150%)",  mr: "10px",}} />
                    ) : (
                        <></>
                    );

                    return (
                        <Box>
                            <li {...props}>
                                {role_img}
                                {team_img}
                                <Typography sx={{
          whiteSpace: "nowrap",  // Prevents multiline
          overflow: "hidden",    // Hides overflowing text
          textOverflow: "ellipsis", // Adds "..."
          maxWidth: "250px",    // Adjust width based on your layout
          display: "block"       // Ensures correct behavior inside MUI components
        }}>{option.player}</Typography>
                            </li>
                        </Box>
                    );
                }}
                ListboxProps={{ className: "scrollable" }}
            />
        </FormControl>
    );
}

export function ScrimPlayerSelect(props: { value: string|null; onChange: any }) {
    const scrimAccounts = useReferentialContext().scrimAccountsReferential;
    return (
        <FormControl>
            <Autocomplete options={scrimAccounts} value={props.value ? props.value : undefined} onChange={(event, newValue) => props.onChange(newValue)} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Player" />} />
        </FormControl>
    );
}
