import {Grow, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {Bar, Radar} from "react-chartjs-2";
import React from "react";
import {ArcElement, Chart, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip} from "chart.js";
import PluginService from "chart.js/dist/core/core.plugins";
import {GridLabelProps, PointData, RadarCustomLayerProps, ResponsiveRadar} from "@nivo/radar";
import {Datum} from "@nivo/legends/dist/types/types";
import positioners from "chart.js/dist/plugins/plugin.tooltip";

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
);

export default function GenericRadarChart(props:{
    data: any[],
    mainLabel: string;
    getValue: (item: any) => number,
    getComparisonValue?: (item: any) => number,
    comparisonLabel?: string,
    labels: (item: any) => string | number,
    rollingAverageWindow? : number,
    vertical?: boolean
}){

    const options = {
        responsive: true,
        scale: {
            r: {
                min: 0,
                max: 0.45,
            },
            y: {
                grid: {
                    color: 'red',
                },
            }
        }
    };

    let datasets = [
        {
            label: props.mainLabel,
            data: props.data.map(props.getValue),
            backgroundColor: "rgba(0, 255, 255, 0.5)",
            borderColor: "rgba(0, 255, 255, 0.5)",
            fill: true
        },
    ]

    if(props.getComparisonValue && props.comparisonLabel){
        const comparison_dataset = {
            label: props.comparisonLabel,
            data: props.data.map(props.getComparisonValue),
            backgroundColor: "rgba(190, 82, 42, 0.5)",
            borderColor: "rgba(190, 82, 42, 0.5)",
            fill: true
        }
        datasets.push(comparison_dataset);
    }

    const data = {
        labels:  props.data.map(props.labels),
        datasets: datasets
    };

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {/* @ts-ignore*/}
                <Radar options={options} data={data} />
            </Box>
        </Grow>
    );

}


const LabelComponent = ({ id, x, y, anchor }: GridLabelProps) => (
    <g transform={`translate(${x}, ${y})`}>
        <g transform={`translate(${anchor === 'end' ? -60 : anchor === 'middle' ? 0 : 60}, ${anchor === 'end' ? 0 : anchor === 'middle' ? -20 : 0})`} style={{textAlign:'center',textJustify:'inter-character',display:'flex'}}>
            {/*<div style={{alignItems:'center',justifyContent:'center',display:'flex'}}>*/}
            <text style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    fill: '#ffffff',
                }}><tspan text-anchor="middle">{id.split('\n')[0]}</tspan>
            </text>
            <text
                y={20}
                style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    fill: '#3a9896',
                }}
            >
                <tspan text-anchor="middle">
                {id.split('\n')[1]}
                </tspan>
            </text>
            {/*</div>*/}
        </g>
    </g>
)



export function GenericRadarChartNivo(props:{
    data: any[],
    mainLabel: string;
    valueKey: string,
    comparisonValueKey?: string,
    comparisonLabel?: string,
    labels: Datum[],
    rollingAverageWindow? : number,
    vertical?: boolean,
    showLegend?: boolean,
    valueType?: string
}){
    const vtype = props.valueType ? props.valueType : 'percent';
    let vformat = (value: number) => `${(value*100).toFixed(1)}%`
    let max = 0.5;
    let ticks = 6;
    switch (vtype){
        case 'percent':
            vformat = (value: number) => `${(value*100).toFixed(1)}%`;
            max = 0.5;
            break;
        case 'normalized_rating':
            vformat = (value: number) => `${(value).toFixed(1)}`;
            max = 3;
            break;
        default:
            vformat = (value: number) => `${(value*100).toFixed(1)}%`;
            max = 0.5;
            break
    }


    return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                <ResponsiveRadar
                    data={props.data}
                    keys={props.comparisonValueKey ? [props.comparisonValueKey, props.valueKey] : [props.valueKey]}
                    indexBy="label"
                    valueFormat={vformat}
                    enableDots={false}
                    enableDotLabel={false}
                    margin={{ top: 70, right: 100, bottom: 20, left: 120 }}
                    borderColor={{ from: 'color' }}
                    gridLabelOffset={10}
                    dotSize={10}
                    maxValue={max}
                    // minValue={0}

                    dotColor={'inherit'}
                    dotBorderWidth={2}
                    colors={{ scheme: 'nivo' }}
                    blendMode="normal"
                    motionConfig="gentle"
                    curve={'cardinalClosed'}
                    gridLabel={LabelComponent}
                    gridLevels={ticks}
                    theme={{
                        tooltip: {
                            wrapper: {
                                color: 'white',
                                background: 'blue',
                            },
                            container: {
                                color: 'white',
                                background: '#3a9896',
                            },
                            tableCell: {
                                color: 'black',
                            }
                        },
                        legends: {
                            text: {
                                fontSize: 18
                            }
                        },
                        axis: {
                            ticks: {
                                text: {
                                    fill: '#ffffff',
                                    fontSize: 12,
                                    fontWeight: 600,
                                },
                            },

                        },
                    }}
                    legends={props.showLegend ? [
                        {
                            data: props.labels,
                            anchor: 'top-left',
                            direction: 'column',
                            translateX: 0,
                            translateY: 0,
                            itemWidth: 30,
                            itemHeight: 30,
                            itemTextColor: '#ffffff',
                            symbolSize: 20,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#ffffff'
                                    }
                                }
                            ]
                        }
                    ]: []}

                />
            </Box>
    );

}