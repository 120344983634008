import React, { useContext, useEffect, useState } from "react";
import {Stack, Typography} from "@mui/material";
import {targets} from "../../../../Context";
import dayjs, { Dayjs } from "dayjs";
import JunglePathingMap from "../../../../components/maps/JunglePathingMap";
import FilterSet from "../../../../components/selectors/Filterset";
import { useReferentialContext } from "../../../../Context";
import {GamesData, ScrimsData, Team} from "../../../../farsight-api";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {SideSelect} from "../../../../components/selectors/YesNoSelector";
import PaginatedStack from "../../../../components/Utilities/PaginatedStack";



export default function JunglePathing(props: {target: targets}) {
    const [team, setTeam] = useState(useReferentialContext().selectedTeam);
    const [side, setSide] = useState(null as string | null);
    const [from, setFrom] = useState(dayjs().subtract(90, "day"));

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} team={team} setTeam={setTeam} side={side} setSide={setSide} date__gte={from} setDateGte={setFrom} />
            {team ? <PathingMaps from={from} team={team} target={props.target} /> : <></>}
        </WindowContainer>
    );
}

export function PathingMaps(props: { team: Team; from: Dayjs, target: targets }) {
    const [games, setGames] = useState([] as GamesData[]);
    const [side, setSide] = useState(null as string | null);
    const api = useReferentialContext().farsightApi;

    useEffect(() => {
        setGames([]);

        api.getDataApi(props.target).list({
            additional_filters: {date__gte: props.from.toISOString()},
            team1: props.team.team_name,
            ordering: ['-date']
        }).then((data) => setGames(data as GamesData[]));

        // api.g({
        //     team: props.team.team_name,
        //     target: props.target,
        //     from: props.from.unix(),
        //     until: dayjs().unix(),
        // }).then((data) => {
        //     setGames(data as any[]);
        // });

    }, [props.team, props.from]);

    return (
        <Stack sx={{width:'100%', height:'100%', alignItems:'center', justifyContent:'center', display:'flex'}} spacing={3}>
            <SideSelect value={side} onChange={setSide}/>
        <Stack
            direction={"row"}
            sx={{
                width: "100%",
                height:'100%',
                flexWrap: "wrap",
                flexDirection: "row",
                flex: 1,
            }}
            alignItems={"flex-start"}
            justifyContent={"space-evenly"}
            alignSelf={"space-evenly"}
        >

            {games.filter((game: any, index: number) => game.irelia_parser_version === 1 && index < 30 && (side === 'Blue' ? props.team.team_name === game.team1 :
            side === 'Red' ? props.team.team_name === game.team2 : true))
                .map((game: any, index: number) => (
                    <JunglePathingMap team={props.team.team_name} match={game} index={index} target={props.target} sx={{width:'430px', height:'570px', mt:3}}/>
                ))}
        </Stack>
        </Stack>
    );
}
