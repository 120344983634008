import {Player} from "../../farsight-api";
import {targets, useReferentialContext} from "../../Context";
import {useEffect, useState} from "react";
import {GenericRadarChartNivo} from "./GenericRadar";
import Box from "@mui/material/Box";


export default function PlayerPerformanceProfile(props: {player: Player, paramObject: any, target: targets}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const teamfights_api = useReferentialContext().farsightApi.competitiveParticipantTeamfights;
    const [data, setData] = useState([] as any[]);

    useEffect(() => {

        if(props.paramObject.player){
            api.aggregate({
            ...props.paramObject,
            groupby: ['player'],
            aggregates: ['performance_lane__avg,relative_performance_lane__avg'],
            metrics: ['performance,relative_performance'],
            ordering: ['performance']
        }).then((r: any) => {
            teamfights_api.aggregate({
                additional_filters: {
                    ...props.paramObject.additional_filters,
                    teamfight__team1_kills__gt:0,
                    teamfight__team2_kills__gt:0,
                    // game_summary__league__in: props.paramObject.additional_filters.league__in
                },
                ...props.paramObject,
                groupby: ['player'],
                metrics: ['performance,relative_performance'],
                ordering: ['performance']
                }
            ).then((r_tf: any) => {
                console.log(r_tf)
                setData([
                    {label: `Overall\n${r[0].performance.toFixed(1)}(${r[0].relative_performance > 0 ? '+' : ''}${r[0].relative_performance.toFixed(1)})`, performance: (r[0].performance + 200) / 100 , relative_performance: (r[0].relative_performance + 120) / 60, average:2},
                    {label: `Lane\n${r[0].performance_lane__avg.toFixed(1)}(${r[0].relative_performance_lane__avg > 0 ? '+' : ''}${r[0].relative_performance_lane__avg.toFixed(1)})`, performance: (r[0].performance_lane__avg + 200) / 100, relative_performance: (r[0].relative_performance_lane__avg + 180) / 90, average:2},
                    {label: `Teamfights\n${r_tf[0].performance.toFixed(1)}(${r_tf[0].relative_performance > 0 ? '+' : ''}${r_tf[0].relative_performance.toFixed(1)})`, performance: (r_tf[0].performance + 200) / 100 , relative_performance: (r_tf[0].relative_performance + 200) / 100, average:2},
                ]);
            }).catch(e => setData([
                    {label: `Overall\n${r[0].performance.toFixed(1)}(${r[0].relative_performance > 0 ? '+' : ''}${r[0].relative_performance.toFixed(1)})`, performance: (r[0].performance + 200) / 100 , relative_performance: (r[0].relative_performance + 120) / 60, average:2},
                    {label: `Lane\n${r[0].performance_lane__avg.toFixed(1)}(${r[0].relative_performance_lane__avg > 0 ? '+' : ''}${r[0].relative_performance_lane__avg.toFixed(1)})`, performance: (r[0].performance_lane__avg + 200) / 100, relative_performance: (r[0].relative_performance_lane__avg + 180) / 90, average:2},
                ])).catch(e => setData([
                    {label: `Overall\n${r[0].performance.toFixed(1)}(${r[0].relative_performance > 0 ? '+' : ''}${r[0].relative_performance.toFixed(1)})`, performance: (r[0].performance + 200) / 100 , relative_performance: (r[0].relative_performance + 120) / 60, average:2},
                ])).catch(e => setData([]))
        });
        }

    }, [props.paramObject.watermark, props.target])

    const labels = [
        {id: `relative_performance`, label: 'Relative Performance'},
        {id: `performance`, label: 'Performance'},
        // {id: `average`, label: 'Average'},
    ]

    return <Box sx={{width:'100%',height:'100%'}}>
        <GenericRadarChartNivo showLegend={false} data={data} labels={labels} comparisonValueKey={'relative_performance'} valueKey={'performance'} mainLabel={'Performance'} comparisonLabel={'Relative Performance'}
        valueType={'normalized_rating'}/>
    </Box>
}