import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material";
import {ThemeProviderComponent} from "./themeContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProviderComponent>
            <App/>
        </ThemeProviderComponent>
    </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
