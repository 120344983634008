import {targets, useReferentialContext} from "../../../Context";
import {useEffect, useState} from "react";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {League, Team} from "../../../farsight-api";
import dayjs from "dayjs";
import GenericBarChart from "../../../components/Charts/GenericBars";
import {Select, Stack, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


export default function DraftingSimDashboard(props: {target: targets}){
    const api = useReferentialContext().farsightApi.getDataApi(props.target);
    const [groupBy, setGroupBy] = useState('league');
    const [paramObject, setParamObject] = useState({watermark: undefined, additional_filters: {drafting_sim_pred_accurate__isnull: false}});
    const [team, setTeam] = useState(undefined as undefined | Team);
    const [leagues, setLeagues] = useState([] as League[]);
    const [from, setFrom] = useState(dayjs().subtract(2, 'weeks'));
    const [until, setUntil] = useState(dayjs());
    const [patches, setPatches] = useState(useReferentialContext().selectedPatches);
    const [data, setData] = useState([] as any[]);
    const [overallResults, setOverallResults] = useState([] as any[]);
    const [sim, setSim] = useState('drafting_sim_pred_accurate__avg');

    useEffect(() => {
        if(paramObject.watermark) {
            api.aggregate({
                    ...paramObject,
                    groupby: [groupBy],
                    aggregates: [[sim, 'date__min'].toString()],
                    metrics: ['games'],
                    ordering: ['date__min']
                }
            ).then(
                r => setData(r)
            )
            api.aggregate({
                    ...paramObject,
                    groupby: ['patch'],
                    aggregates: [[sim, 'date__min'].toString()],
                    metrics: ['games'],
                    ordering: ['date__min']
                }
            ).then(
                r => {
                    console.log(r);
                    setOverallResults(r);
                }
            )
        }
    }, [paramObject.watermark, groupBy, sim])

    return <WindowContainer direction={'column'} spacing={2}>
        <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={props.target} team={team} setTeam={setTeam} leagues={leagues} setLeagues={setLeagues} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil} patches={patches} setPatches={setPatches}/>
        <Select onChange={(event) => setGroupBy(event.target.value as string)} value={groupBy} sx={{width: '300px'}}>
            <MenuItem value={'date__date'}>Date</MenuItem>
            <MenuItem value={'league'}>League</MenuItem>
            <MenuItem value={'patch'}>Patch</MenuItem>
        </Select>
        <Select onChange={(event) => setSim(event.target.value as string)} value={sim} sx={{width: '300px'}}>
            <MenuItem value={'drafting_sim_pred_accurate__avg'}>Draft V1</MenuItem>
            <MenuItem value={'drafting_sim_v3_pred_accurate__avg'}>Draft V3</MenuItem>
            <MenuItem value={'drafting_sim_soloq_v1_pred_accurate__avg'}>Drafts From SoloQ</MenuItem>
            <MenuItem value={'pre_game_pred_accurate__avg'}>Standard Odds</MenuItem>
        </Select>
        <Stack direction={'row'}>
        {overallResults.length > 0 ?
            <Stack direction={'column'} spacing={1} sx={{width:'25%'}}>
                {
                    overallResults.map((patchResult: any) => {
                        return <Stack direction={'row'} spacing={1}>
                            <Typography>{`Patch ${patchResult.patch} Games: ${patchResult.games} Accuracy: ${(patchResult[sim] * 100).toFixed(2)}%`}</Typography>
                        </Stack>
                    })
                }
            </Stack> : <></>}
        {data.length > 0 ? <GenericBarChart data={data}
                          mainLabel={'Accuracy'}
                          getValue={(row: any) => row[sim]}
                          labels={(row: any) => row[groupBy]}
                          rollingAverageWindow={3}
                          // comparisonLabel={'Games'}
                          // getComparisonValue={(row: any) => row.games}
            />
            : <></>}
        </Stack>

    </WindowContainer>
}