import React, {useContext, useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {Champion, targets, useReferentialContext} from "../../../../Context";
import FilterSet from "../../../../components/selectors/Filterset";
import {GameMetrics} from "../../../../farsight-api";
import MultipleMetricsSelect from "../../../../components/selectors/Shared/MetricsSelect";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import WindowContainer from "../../../../components/layout/WindowContainer";
import dayjs from "dayjs";
import {NeonTab, NeonTabs} from "../../../../components/Styles/NeonTabs";
import TabPanel from "../../../../components/tabs_switch/tabs_panel";

export default function PlayersTierlists(props: { target: targets }) {
    const context = useReferentialContext();
    const [year, setYear] = useState(dayjs().year());
    const [league, setLeague] = useState(context.leaguesReferential.filter(l => l.league_name === 'LEC')[0] ? context.leaguesReferential.filter(l => l.league_name === 'LEC')[0] : null);
    const [champions, setChampions] = useState([] as Champion[]);
    const [split, setSplit] = useState(null as string | null);
    const [phases, setPhases] = useState([] as string[]);
    const [laneSwap, setLaneSwap] = useState(null as boolean|null);
    const [tab, setTab] = useState(0);

    const [metrics, setMetrics] = useState<GameMetrics[]>(context.getMetrics(
        ['games','winrate','performance','relative_performance','teamwise_adjusted_relative_performance','kda','dpm','forward_percentage_pre_15','gd15','csd15','performance_lane','relative_performance_lane'], props.target
    ));

    const [paramObject, setParamObject] = useState({
        groupby: "player,team1",
        metrics: tab === 0 ? ['games,winrate,performance,relative_performance'] : metrics.map((metric) => metric.code).toString(),
        ordering: "performance",
        format: "json",
        watermark: 'initial',
        league: 'LEC',
        additional_filters: {date__year:dayjs().year()}
    } as any);

    function encapsulateParamObject(newParamObject: any){
        console.log('New param object')
        const np = {
            ...newParamObject,
            groupby: "player,team1",
            metrics: tab === 0 ? ['games,winrate,performance,relative_performance'] : metrics.map((metric) => metric.code).toString(),
            role : [undefined, 'TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab],
            ordering: "performance",
            format: "json",
            watermark: newParamObject.watermark + metrics.map((metric) => metric.code).toString() + [undefined, 'TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]
        };
        setParamObject(np)
    }


    useEffect(() => {
        setParamObject({
            ...paramObject,
            metrics: metrics.map((metric) => metric.code).toString(),
            role: [undefined, 'TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab],
            watermark: paramObject.watermark + metrics.map((metric) => metric.code).toString() + [undefined, 'TOP_LANE','JUNGLE','MID_LANE','BOT_LANE','UTILITY'][tab]
        })
    }, [metrics, tab]);


    return (
        <WindowContainer direction={"column"} spacing={2}>
            <Stack direction={'row'} sx={{alignItems:'space-between', display:'flex', justifyContent:'center', height:'60px', maxHeight:'60px', minHeight:'60px'}}>
                <FilterSet
                paramObject={paramObject}
                setParamObject={encapsulateParamObject}
                target={targets.competitive}
                league={league}
                setLeague={setLeague}
                year={year}
                setYear={setYear}
                split={split}
                setSplit={setSplit}
                phases={phases}
                setPhases={setPhases}
                champions={champions}
                setChampions={setChampions}
                updateContext={false}
                laneSwap={laneSwap}
                setLaneSwap={setLaneSwap}
            />
                {tab != 0 ? <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={targets.competitive}/> : <></>}
            </Stack>
            <Stack sx={{height:'60px',width: "100%" }}>
                <NeonTabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <NeonTab label="Overview"/>
                    <NeonTab label="Top" asset={'TOP_LANE'} />
                    <NeonTab label="Jungle" asset={'JUNGLE'} />
                    <NeonTab label="Mid" asset={'MID_LANE'} />
                    <NeonTab label="ADC" asset={'BOT_LANE'} />
                    <NeonTab label="Support" asset={'UTILITY'}/>
                </NeonTabs>
            </Stack>
            {
                paramObject.role ?
                    <TierlistDisplay target={props.target} paramObject={paramObject} metrics={metrics}/> :
                    (paramObject.additional_filters && paramObject.watermark ? <OverviewTierlists paramObject={paramObject} target={props.target}/> : <></>)

            }
        </WindowContainer>
    );
}

function TierlistDisplay(props: {target: targets, paramObject: any, metrics?: GameMetrics[], disp_column?: string[]}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    return props.paramObject.role ? <TableDisplayGrid
                    display_all={false}
                    request={() => api.aggregate(props.paramObject)}
                    rowStyle={rowStyle.none}
                    disp_columns={props.disp_column ? props.disp_column : ["player", "team1"]}
                    sortBy={"performance"}
                    paramObject={props.paramObject}
                    metrics={props.metrics}
                /> : <></>
}

function OverviewTierlists(props: { paramObject: any, target: targets}) {
    const columns = ["player", "team1", "games", "winrate", "performance", "relative_performance"];
    return (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TierlistDisplay target={props.target} paramObject={{...props.paramObject, role: "TOP_LANE"}} disp_column={columns}/>
            <TierlistDisplay target={props.target} paramObject={{...props.paramObject, role: "JUNGLE"}} disp_column={columns}/>
            <TierlistDisplay target={props.target} paramObject={{...props.paramObject, role: "MID_LANE"}} disp_column={columns}/>
            <TierlistDisplay target={props.target} paramObject={{...props.paramObject, role: "BOT_LANE"}} disp_column={columns}/>
            <TierlistDisplay target={props.target} paramObject={{...props.paramObject, role: "UTILITY"}} disp_column={columns}/>
        </Stack>
    );
}