import {ChartsReferenceLine, LinePlot, ScatterChart} from "@mui/x-charts";
import {targets, useReferentialContext} from "../../Context";
import React, {useEffect, useState} from "react";
import {SoloQAccounts} from "../../farsight-api";
import {Stack} from "@mui/material";
import VariableDistribution from "./VariableDistribution";
import SelectIntervalBetween from "../selectors/Shared/SelectIntervalBetween";


export default function PerformancePerElo(props: {accounts: SoloQAccounts[], year?: number|undefined}){
    const api = useReferentialContext().farsightApi.soloqGameSummaries;
    const [performancePoints, setPerformancePoints] = useState([] as any[]);
    const [eloInterval, setEloInterval] = useState([0,3000]);
    const paramObject = {additional_filters:{
                                date__year: props.year,
                                account_id__in: [props.accounts.map(acc => acc.id).join(',')],
                                performance__isnull: false,
                                elo_as_total_lps_during_dame__gte: eloInterval[0] + 3100,
                                elo_as_total_lps_during_dame__lte: eloInterval[1] + 3100,
                            },
                        include_in_performance:true} as any;
    paramObject.watermark = JSON.stringify(paramObject);

    useEffect(() => {
        setPerformancePoints([]);
        api.list({
            additional_filters:{
                    date__year: props.year,
                    account_id__in: [props.accounts.map(acc => acc.id).join(',')],
                    performance__isnull: false,
                    elo_as_total_lps_during_dame__gte: eloInterval[0] + 3100,
                    elo_as_total_lps_during_dame__lte: eloInterval[1] + 3100,
                },
            include_in_performance:true,
            ordering: ['elo_as_total_lps_during_dame']
        }).then(r => setPerformancePoints(r));
    }, [props.year, props.accounts, eloInterval]);

    return <Stack direction={'column'} sx={{width:'100%',height:'93%'}}>
        <SelectIntervalBetween value={eloInterval} onChange={setEloInterval} max={3000} min={0}></SelectIntervalBetween>
    <Stack direction={'row'} spacing={1} sx={{height:'100%',width:'100%'}}>
                <ScatterChart grid={{ vertical: true, horizontal: true }} yAxis={[{min:-300, max:300}]}
                                     series={[{
                                        label: `Relative Performance, ${performancePoints.length} games`,
                                        data: performancePoints.map((v) => ({ x: v.elo_as_total_lps_during_dame-3100, y: v.relative_performance, id: v.game_id })),
                                    }]}
                >
                    <ChartsReferenceLine y={"0"} label="Global Average" labelAlign="start" lineStyle={{border:'1px solid red'}}/>
                </ScatterChart>
                <Stack direction={'column'} sx={{height:'100%', width:'50%'}}>
                    <VariableDistribution paramObject={paramObject}
                        target={targets.soloq} metric={'performance'} label={'Performance'}
                        bucket_size={30} min={-300} max={300}
                        gaussianParameters={[
                          {mean: 0, std: 100, label:'Global', multiplier:1},
                          {mean: -76, std: 65, label:'Loss', multiplier:0.5},
                          {mean: 76, std: 65, label:'Wins', multiplier:0.5},
                        ]}
                    />
                    <VariableDistribution paramObject={paramObject}
                        target={targets.soloq} metric={'relative_performance'} label={`Relative Performance`}
                        bucket_size={20} min={-250} max={250} gaussianParameters={[
                          {mean: 0, std: 65, label:'Global Distribution', multiplier:1}
                        ]}
                    />
                </Stack>
        </Stack>
        </Stack>
}