import React, { useState } from "react";
import DraftingMetagameBlock from "../../../../components/visu_blocks/DraftingMetagame";
import FilterSet from "../../../../components/selectors/Filterset";
import { League } from "../../../../farsight-api";
import {targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import {Grid, Grow} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../../components/tabs_switch/tabs_panel";
import {DraftsBlock} from "../../2_Scouting/8_DraftScouting/TeamDrafts";
import {NeonTab, NeonTabs} from "../../../../components/Styles/NeonTabs";

export default function DraftingMetagameCompetitive() {
    const context = useReferentialContext();

    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);
    const [paramObject, setParamObject] = useState({} as any);
    const [gameNumbers, setGameNumbers] = useState([] as number[]);
    const [tab, setTab] = useState(0);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject}
                       setParamObject={setParamObject}
                       target={targets.competitive}
                       updateContext={false}
                       leagues={leagues}
                       setLeagues={setLeagues}
                       patches={patches}
                       setPatches={setPatches}
                       gameNumbers={gameNumbers}
                       setGameNumbers={setGameNumbers}
            />
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <NeonTabs value={tab} onChange={(event, newvalue) => setTab(newvalue)} sx={{ height: "50px" }}>
                    <NeonTab label="Stats"  />
                    <NeonTab label="History"  />
                    <NeonTab label="Priorities"  />
                </NeonTabs>
            </Grow>
            <TabPanel value={tab} index={0}>
                {paramObject.additional_filters? <DraftingMetagameBlock target={targets.competitive} paramObject={paramObject}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Grid
                    container
                    sx={{
                        mt: 5,
                        mb: 5,
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    {paramObject.additional_filters ? <DraftsBlock paramObject={paramObject} target={targets.competitive} /> : <></>}
                </Grid>
            </TabPanel>
        </WindowContainer>
    );
}
