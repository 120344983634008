import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useContext, useState } from "react";
import global_context from "../../static_vars";
import {FormControl, InputLabel, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
    return `Season ${value}`;
}

export default function YearSelect(props: { value: number; onChange: any }) {
    const [value, setValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(false); // Track focus for styling
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "150px",
                height: "56px",
                borderRadius: 1,
                padding: "12px 16px",
                border: isFocused ? "1px solid white" : "1px solid rgba(255, 255, 255, 0.6)",
                "&:hover": { border: "1px solid white" }, // Thicker border on hover
                "&:focus-within": { border: "1px solid white" }, // Thicker border on focus
            }}
            onMouseEnter={() => setIsFocused(true)}
            onMouseLeave={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            {/* Floating Label like MUI TextField */}
            <Typography
                variant="caption"
                sx={{
                    position: "absolute",
                    top: isFocused || value ? "-10px" : "50%", // Ensure proper centering
                    left: "12px",
                    transform: isFocused || value ? "none" : "translateY(-50%)", // Align properly
                    fontSize: isFocused || value ? "12px" : "16px",
                    color: isFocused ? "white" : "rgba(255, 255, 255, 0.7)",
                    backgroundColor: "#121212", // Matches MUI TextField behavior
                    padding: "0 4px",
                }}
            >
                {`Year : ${value}`}
            </Typography>
            <Slider aria-label="Year"
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto" step={1}
                    marks min={2010}
                    max={new Date().getFullYear()}
                    onChange={handleChange}
                    onChangeCommitted={(event, value) => props.onChange(value)} value={value}
            />
        </Box>
    );
}

export function SplitSelect(props: { value: string | null; onChange: any }) {
    return (
        <FormControl sx={{ width: "130px" }}>
            <InputLabel>Split</InputLabel>
            <Select value={props.value ? props.value : undefined} label={"Split"} onChange={(event) => (event.target.value ? props.onChange(event.target.value) : props.onChange(null))}>
                <MenuItem value={undefined}>All</MenuItem>
                <MenuItem value={"Winter"}>Winter</MenuItem>
                <MenuItem value={"Spring"}>Spring</MenuItem>
                <MenuItem value={"Summer"}>Summer</MenuItem>
                <MenuItem value={"Split 1"}>Split 1</MenuItem>
                <MenuItem value={"Split 2"}>Split 2</MenuItem>
                <MenuItem value={"Finals"}>Finals</MenuItem>
            </Select>
        </FormControl>
    );
}

export function PhaseSelect(props: { value: string[]; onChange: any }) {
    return (
        <FormControl sx={{ width: "170px" }}>
            <InputLabel>Stage</InputLabel>
            <Select multiple value={props.value} label={"Stage"} onChange={(event) => props.onChange(event.target.value)}>
                <MenuItem value={"Regular Season"}>Regular Season</MenuItem>
                <MenuItem value={"Tiebreakers"}>Tiebreakers</MenuItem>
                <MenuItem value={"Playoffs"}>Playoffs</MenuItem>
                <MenuItem value={"Knockout"}>Knockout</MenuItem>
                <MenuItem value={"Group"}>Group</MenuItem>
                <MenuItem value={"Group Stage"}>Group Stage</MenuItem>
            </Select>
        </FormControl>
    );
}

export function GameNumbersSelect(props: { value: number[]; onChange: React.Dispatch<React.SetStateAction<number[]>> }) {
    const [tempValue, setTempValue] = useState<number[]>(props.value);

    return (
        <FormControl sx={{ width: "170px" }}>
            <InputLabel>Game Number (Fearless)</InputLabel>
            <Select
                multiple
                value={tempValue}
                label="Game Number (Fearless)"
                onChange={(event) => setTempValue(event.target.value as number[])}
                onClose={() => props.onChange(tempValue)} // Update the parent state only when closing
            >
                {[1, 2, 3, 4, 5].map((num) => (
                    <MenuItem key={num} value={num}>
                        {num}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
