import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {Champion, targets, useReferentialContext} from "../../../Context";
import React, {useEffect, useState} from "react";
import {Grow, Stack} from "@mui/material";
import {SingleChampionSelect} from "../../../components/selectors/Shared/ChampionSelect";
import {League} from "../../../farsight-api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import {DraftsBlock} from "../2_Scouting/8_DraftScouting/TeamDrafts";
import TableDisplayGrid, {rowStyle} from "../../../components/datagrids/shared/TableDisplayGrid";
import Box from "@mui/material/Box";
import LeagueMatchHistory from "../4_Broadcast/LeagueHistory";


export default function CheckChampionSituationsCompetitive(){
    const [ally1, setAlly1] = useState(null as Champion|null);
    const [ally2, setAlly2] = useState(null as Champion|null);
    const [ally3, setAlly3] = useState(null as Champion|null);
    const [ally4, setAlly4] = useState(null as Champion|null);
    const [ally5, setAlly5] = useState(null as Champion|null);

    const [enemy1, setEnemy1] = useState(null as Champion|null);
    const [enemy2, setEnemy2] = useState(null as Champion|null);
    const [enemy3, setEnemy3] = useState(null as Champion|null);
    const [enemy4, setEnemy4] = useState(null as Champion|null);
    const [enemy5, setEnemy5] = useState(null as Champion|null);

    const [paramObject, setParamObject] = useState({} as any);
    const [leagues, setLeagues] = useState([] as League[]);
    const [patches, setPatches] = useState([] as string[]);
    const [paramObjectFinal, setParamObjectFinal] = useState({} as any);

    useEffect(() => {
        const np = {
            ...paramObject
        }
        if(ally1) np.ally1 = ally1.id;
        if(ally2) np.ally2 = ally2.id;
        if(ally3) np.ally3 = ally3.id;
        if(ally4) np.ally4 = ally4.id;
        if(ally5) np.ally5 = ally5.id;

        if(enemy1) np.enemy1 = enemy1.id;
        if(enemy2) np.enemy2 = enemy2.id;
        if(enemy3) np.enemy3 = enemy3.id;
        if(enemy4) np.enemy4 = enemy4.id;
        if(enemy5) np.enemy5 = enemy5.id;

        setParamObjectFinal(np);

    }, [paramObject.watermark, ally1, ally2, ally3, ally4, ally5, enemy1, enemy2, enemy3, enemy4, enemy5 ])


    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive}
            leagues={leagues} setLeagues={setLeagues} patches={patches} setPatches={setPatches}/>
            <Stack direction={'row'} spacing={2}>
                <SingleChampionSelect value={ally1} updateValue={setAlly1} label={'Ally Top'}/>
                <SingleChampionSelect value={ally2} updateValue={setAlly2} label={'Ally Jungle'}/>
                <SingleChampionSelect value={ally3} updateValue={setAlly3} label={'Ally Mid'}/>
                <SingleChampionSelect value={ally4} updateValue={setAlly4} label={'Ally ADC'}/>
                <SingleChampionSelect value={ally5} updateValue={setAlly5} label={'Ally Support'}/>
            </Stack>

            <Stack direction={'row'} spacing={2}>
                <SingleChampionSelect value={enemy1} updateValue={setEnemy1} label={'Enemy Top'}/>
                <SingleChampionSelect value={enemy2} updateValue={setEnemy2} label={'Enemy Jungle'}/>
                <SingleChampionSelect value={enemy3} updateValue={setEnemy3} label={'Enemy Mid'}/>
                <SingleChampionSelect value={enemy4} updateValue={setEnemy4} label={'Enemy ADC'}/>
                <SingleChampionSelect value={enemy5} updateValue={setEnemy5} label={'Enemy Support'}/>
            </Stack>

            {paramObjectFinal.watermark && paramObjectFinal.additional_filters.patch__in ? <ChampionSituations target={targets.competitive} paramObject={paramObjectFinal}/> : <></>}

        </WindowContainer>
    );
}

export function ChampionSituations(props: {paramObject: any, target: targets}){
    const context = useReferentialContext();

    const [championStats, setChampionStats] = useState([] as any[]);
    const [gameIds, setGameIds] = useState([] as string[]);
    const [tab, setTab] = useState(0);

    const [draftsQueryParams, setDraftsQueryParams] = useState({} as any);

    useEffect(() => {
        const query_params = {
            ...props.paramObject,
            groupby: ['champion'],
            ordering: ['-games'],
            metrics: ['games,winrate,performance,relative_performance,game_list,gd15,csd15,xpd15'],
        }
        setGameIds([]);
        context.farsightApi.getSummariesApi(props.target).aggregate(query_params).then(
            (data: any[]) => {
                console.log(data)
                setChampionStats(data);
                const gameids = [] as string[];
                for(const i in data){
                    let games = data[i].game_list.split(',');
                    for(const id in games){
                        let gameid = games[id];
                        if(!gameids.includes(gameid)){
                            gameids.push(gameid);
                        }
                    }
                }
                console.log(gameids)
                setGameIds(gameids);
                const ndqp = {
                    additional_filters: {
                        game_id__in: gameids.toString()
                    },
                    watermark: gameids.toString()
                }
                setDraftsQueryParams(ndqp);
            }
        );
    }, [props.paramObject])

    async function request(){
        return championStats;
    }

    return <Stack direction={"column"} spacing={2} sx={{height:'100%'}}>
        <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
            <Tabs value={tab} aria-label="basic tabs example" onChange={(event, newValue) => setTab(newValue)} sx={{ height: "50px" }}>
                <Tab label="Drafts" {...a11yProps(0)} />
                <Tab label="Ally Stats" {...a11yProps(1)} />
            </Tabs>
        </Grow>
        <TabPanel index={0} value={tab}>
            <Stack direction={'row'} sx={{height:'100%', width:'100%'}}>
                { gameIds.length > 0 ? <Box sx={{width:'55%', height:'100%'}}><DraftsBlock paramObject={draftsQueryParams} target={props.target}/></Box> : <></>}
                { gameIds.length > 0 ? <Box sx={{width:'45%', height:'70%'}}><TableDisplayGrid
                display_all={false}
                request={() => context.farsightApi.getDataApi(props.target).list({additional_filters:{game_id__in:[gameIds.toString()]}})}
                rowStyle={rowStyle.outcome}
                sortBy={"date"}
                paramObject={props.paramObject}
                disp_columns={["league", "date", "team1", "team2", "patch", "duration", "winner", "team1champions", "team2champions", "game_id", "vod_game_start"]}
            /></Box> : <></>}
            </Stack>
        </TabPanel>
        <TabPanel index={1} value={tab}>
            <Box sx={{height:'100%'}}>
                <TableDisplayGrid request={() => request()} display_all={true} rowStyle={rowStyle.none}
                                  sortBy={'games'}
                                  paramObject={{watermark:JSON.stringify(championStats)}}/>
            </Box>
        </TabPanel>
    </Stack>
}